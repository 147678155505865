<template>
  <div>
    <div
      style="
        display: block;
        width: 100%;
        padding-top: 60px;
        padding-bottom: 30px;
      "
    >
      <div class="s-btn-tab" style="display: flex; float: right">
        <!-- <div class="s-btn s-btn-type2" @click="onSaveKeyword()" v-if="sidebar!=SIDEMENU_TYPE.MYKEYWORD">
                <img class="s-f-filter-default" src="@/assets/images/v4-icon/s-f-btn1.svg" />키워드 저장
            </div> -->
        <div
          class="s-btn s-btn-type2"
          v-if="sidebar == SIDEMENU_TYPE.MYKEYWORD"
        >
          <img
            class="s-f-filter-default"
            src="@/assets/images/v4-icon/s-f-btn1.svg"
          />키워드 동향보기
        </div>
        <div
          class="s-btn s-btn-type2"
          v-if="mode == 'num'"
          @click="mode = 'text'"
        >
          <img
            class="s-f-filter-default"
            src="@/assets/images/v4-icon/s-f-btn2.svg"
          />상태로 보기
        </div>
        <div class="s-btn s-btn-type2" v-else @click="mode = 'num'">
          <img
            class="s-f-filter-default"
            src="@/assets/images/v4-icon/s-f-btn2.svg"
          />숫자로 보기
        </div>
        <div
          class="s-btn s-btn-type2"
          v-if="Ssidebar == SIDEMENU_TYPE.MYKEYWORD"
        >
          <img
            class="s-f-filter-default"
            src="@/assets/images/v4-icon/s-f-btn1.svg"
          />그룹지정
        </div>

        <export-excel :header="columns" :data="targetData"></export-excel>
      </div>
    </div>

    <div class="new" style="display: block; width: 100%; margin-top: 41px">
      <div
        style="color: black; position: absolute; margin-top: -70px"
        v-if="keyword"
      >
        <div class="s-f-tab-font1">
          <span style="font-weight: bold">{{ keyword }}</span
          >&nbsp;검색결과
        </div>
        <div class="s-f-tab-font2">{{ cumdate }}&nbsp;기준</div>
      </div>
      <div
        style="color: black; position: absolute; margin-top: -70px"
        v-if="category"
      >
        <div class="s-f-tab-font1">
          <span style="font-weight: bold">{{ category }}</span
          >&nbsp;검색결과
        </div>
        <div class="s-f-tab-font2">{{ cumdate }}&nbsp;기준</div>
      </div>
      <div style="display: flex" v-if="viemode == SEARCH_TYPE.COMPLEX">
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.RELATETAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.RELATETAB ? 'active' : '']"
        >
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span
                >네이버 광고시스템에서 연관된 상품 검색어 리스트입니다.</span
              >
            </template>
            <span>연관검색어</span>
          </a-tooltip>
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.AUTOCOMPLEATETAB)"
          v-bind:class="[
            currentTab == SEARCH_TYPE.AUTOCOMPLEATETAB ? 'active' : '',
          ]"
        >
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span>네이버 홈화면에서 자동완성되는 검색어 리스트입니다.</span>
            </template>
            <span>자동완성 검색어</span>
          </a-tooltip>
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.VIEWTAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.VIEWTAB ? 'active' : '']"
        >
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span
                >네이버 view탭(블로그, 카페)과 연관되는 검색어
                리스트입니다.</span
              >
            </template>
            <span>뷰탭연관 검색어</span>
          </a-tooltip>
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.SHOPPINGTAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.SHOPPINGTAB ? 'active' : '']"
        >
          <a-tooltip placement="topLeft">
            <template slot="title">
              <span>네이버 쇼핑탭과 연관되는 검색어 리스트입니다.</span>
            </template>
            <span>쇼핑탭 연관검색어</span>
          </a-tooltip>
        </div>
      </div>
      <div style="display: flex" v-if="viemode == SEARCH_TYPE.SIMPLE">
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.TOP10TAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.TOP10TAB ? 'active' : '']"
        >
          카테고리 Top10
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.TODAYFAVTAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.TODAYFAVTAB ? 'active' : '']"
        >
          오늘의 인기검색어
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.WEEKFAVTAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.WEEKFAVTAB ? 'active' : '']"
        >
          주간 인기검색어
        </div>
        <div
          class="s-f-tab-btn1"
          @click="onSelectTab(SEARCH_TYPE.MONTHFAVTAB)"
          v-bind:class="[currentTab == SEARCH_TYPE.MONTHFAVTAB ? 'active' : '']"
        >
          월간 인기검색어
        </div>
      </div>
      <a-table
        class="s-f-tbl"
        v-bind:class="[tableData.length == 0 ? 'deactive' : '']"
        v-show="
          currentTab == SEARCH_TYPE.RELATETAB ||
          currentTab == SEARCH_TYPE.TOP10TAB ||
          currentTab == SEARCH_TYPE.MYKEYWORD
        "
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :scroll="{ x: 0, y: getHeight2 }"
        :data-source="tableData"
      >
        <div
          slot="shopPower"
          slot-scope="text, record"
          style="text-align: center"
        >
          <div v-if="mode == 'num'">
            <div class="s-f-tag s-f-tag5" v-if="text < 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">
              {{ record.shopPower }}
            </div>
          </div>
          <div v-else>
            <div class="s-f-tag s-f-tag5" v-if="text < 1">추천</div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              좋음
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              보통
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              안좋음
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">비추천</div>
          </div>
        </div>

        <div slot="total" slot-scope="text" style="text-align: right">
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="productCnt"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPurchase"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPrice"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
      </a-table>
      <a-table
        class="s-f-tbl"
        v-bind:class="[tableData.length == 0 ? 'deactive' : '']"
        v-show="currentTab == SEARCH_TYPE.COMPETITIVETAB"
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :columns="columns"
        :scroll="{ x: 0, y: getHeight2 }"
        :data-source="tableData"
      >
        <div slot="shopPower" slot-scope="text" style="text-align: center">
          <div v-if="mode == 'num'">
            <div class="s-f-tag s-f-tag5" v-if="text < 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">
              {{ record.shopPower }}
            </div>
          </div>
          <div v-else>
            <div class="s-f-tag s-f-tag5" v-if="text < 1">추천</div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              좋음
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              보통
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              안좋음
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">비추천</div>
          </div>
        </div>

        <div slot="total" slot-scope="text" style="text-align: right">
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="productCnt"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPurchase"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPrice"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
      </a-table>
      <a-table
        class="s-f-tbl"
        v-bind:class="[tableData.length == 0 ? 'deactive' : '']"
        v-show="
          currentTab == SEARCH_TYPE.AUTOCOMPLEATETAB ||
          currentTab == SEARCH_TYPE.TODAYFAVTAB
        "
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys2,
          onChange: onSelectChange2,
        }"
        :columns="columns"
        :scroll="{ x: 0, y: getHeight2 }"
        :data-source="tableData2"
      >
        <div slot="shopPower" slot-scope="text" style="text-align: center">
          <div v-if="mode == 'num'">
            <div class="s-f-tag s-f-tag5" v-if="text < 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">
              {{ record.shopPower }}
            </div>
          </div>
          <div v-else>
            <div class="s-f-tag s-f-tag5" v-if="text < 1">추천</div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              좋음
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              보통
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              안좋음
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">비추천</div>
          </div>
        </div>

        <div slot="total" slot-scope="text" style="text-align: right">
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="productCnt"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPurchase"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPrice"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
      </a-table>
      <a-table
        class="s-f-tbl"
        v-bind:class="[tableData.length == 0 ? 'deactive' : '']"
        v-show="
          currentTab == SEARCH_TYPE.VIEWTAB ||
          currentTab == SEARCH_TYPE.WEEKFAVTAB
        "
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys3,
          onChange: onSelectChange3,
        }"
        :columns="columns"
        :scroll="{ x: 0, y: getHeight2 }"
        :data-source="tableData3"
      >
        <div slot="shopPower" slot-scope="text" style="text-align: center">
          <div v-if="mode == 'num'">
            <div class="s-f-tag s-f-tag5" v-if="text < 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">
              {{ record.shopPower }}
            </div>
          </div>
          <div v-else>
            <div class="s-f-tag s-f-tag5" v-if="text < 1">추천</div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              좋음
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              보통
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              안좋음
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">비추천</div>
          </div>
        </div>

        <div slot="total" slot-scope="text" style="text-align: right">
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="productCnt"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPurchase"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPrice"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
      </a-table>
      <a-table
        class="s-f-tbl"
        v-bind:class="[tableData.length == 0 ? 'deactive' : '']"
        v-show="
          currentTab == SEARCH_TYPE.SHOPPINGTAB ||
          currentTab == SEARCH_TYPE.MONTHFAVTAB
        "
        :pagination="false"
        :row-selection="{
          selectedRowKeys: selectedRowKeys4,
          onChange: onSelectChange4,
        }"
        :columns="columns"
        :scroll="{ x: 0, y: getHeight2 }"
        :data-source="tableData4"
      >
        <div slot="shopPower" slot-scope="text" style="text-align: center">
          <div v-if="mode == 'num'">
            <div class="s-f-tag s-f-tag5" v-if="text < 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              {{ record.shopPower }}
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">
              {{ record.shopPower }}
            </div>
          </div>
          <div v-else>
            <div class="s-f-tag s-f-tag5" v-if="text < 1">추천</div>
            <div class="s-f-tag s-f-tag4" v-if="text < 6 && text >= 1">
              좋음
            </div>
            <div class="s-f-tag s-f-tag3" v-if="text < 10 && text >= 6">
              보통
            </div>
            <div class="s-f-tag s-f-tag2" v-if="text < 30 && text >= 10">
              안좋음
            </div>
            <div class="s-f-tag s-f-tag1" v-if="text >= 30">비추천</div>
          </div>
        </div>

        <div slot="total" slot-scope="text" style="text-align: right">
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="productCnt"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPurchase"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
        <div
          slot="avgPrice"
          v-if="text"
          slot-scope="text"
          style="text-align: right"
        >
          {{ parseInt(text).toLocaleString() }}
        </div>
      </a-table>
      <div
        style="text-align: center"
        v-if="
          (!keyword && tableData.length == 0) ||
          (keyword && tableData.length == 0)
        "
      >
        <img
          style="width: 260px; margin-top: 121px"
          src="@/assets/images/v4-icon/empty.png"
        />
      </div>
    </div>
    <message
      :visible="showFlag"
      :contents="msg"
      :closeText="closeText"
      @closed="onCloseEvent()"
      @clicked="onClickEvent"
    ></message>
  </div>
</template>

<script>
import {
  getCurrentTimeNew,
  getHex,
  replaceAll,
  chkLicense,
  showError,
  showSuccess,
  isMobile,
  SEARCH_TYPE,
  SIDEMENU_TYPE,
  getToday,
} from "../../components/fnc.js";
import firebase from "firebase";
import XLSX from "xlsx";
import FileSaver from "file-saver";
import ExportExcel from "../ExportExcel/index";
import Message from "../Message/index";

export default {
  props: {
    viemode: {
      type: String,
      default: "",
    },
    keyword: {
      type: String,
      default: "",
    },
    sidebar: {
      type: String,
      default: "",
    },
    code: {
      type: String,
      default: "",
    },
    category: {
      type: String,
      default: "",
    },
  },
  components: {
    ExportExcel,
    Message,
  },
  computed: {
    getHeight: function () {
      return this.$vssHeight - 700;
    },
    getHeight2: function () {
      return this.$vssHeight - 670;
    },
    getName() {
      return this.$store.state.AppActiveUser.displayName;
    },
    getUid() {
      return this.$store.state.AppActiveUser.uid;
    },
    getEmail() {
      return this.$store.state.AppActiveUser.email;
    },
    getUserKey() {
      return this.$store.state.AppActiveUser.key;
    },
    getLevel() {
      return this.$store.state.AppActiveUser.level;
    },
  },
  data() {
    return {
      closeText: "",
      showFlag: false,
      cumdate: "",
      mode: "text",
      currentTab: SEARCH_TYPE.RELATETAB,
      SEARCH_TYPE: SEARCH_TYPE,
      SIDEMENU_TYPE: SIDEMENU_TYPE,
      loading: false,
      tableData: [],
      tableData2: [],
      tableData3: [],
      tableData4: [],
      targetData: [],
      selectedRowKeys: [],
      selectedRowKeys2: [],
      selectedRowKeys3: [],
      selectedRowKeys4: [],
      selectedRows: [],
      selectedRows2: [],
      selectedRows3: [],
      selectedRows4: [],
      columns: [
        {
          title: "키워드",
          etitle: "키워드",
          key: "relKeyword",
          width: "100px",
          dataIndex: "relKeyword",
          seq: 0,
        },
        {
          key: "shopPower",
          etitle: "쇼핑경쟁력",
          dataIndex: "shopPower",
          align: "center",
          width: "100px",
          seq: 1,
          scopedSlots: {
            customRender: "shopPower",
          },
          title: () => {
            return (
              <div style="text-align:center">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>검색량 대비 상품량</span>
                  </template>
                  <span>쇼핑경쟁력</span>
                </a-tooltip>
              </div>
            );
          },
        },
        {
          sorter: true,
          key: "total",
          width: "100px",
          etitle: "네이버 최근 한달 검색량",
          seq: 2,
          dataIndex: "total",
          sorter: (a, b) => a.total - b.total,
          scopedSlots: {
            customRender: "total",
          },
          align: "right",
          title: () => {
            return (
              <div style="text-align:center">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>네이버 최근 한달 검색량</span>
                  </template>
                  <span>검색량</span>
                </a-tooltip>
              </div>
            );
          },
        },
        {
          sorter: true,
          key: "productCnt",
          width: "100px",
          etitle: "네이버쇼핑에 노출되는 상품수",
          seq: 3,
          dataIndex: "productCnt",
          sorter: (a, b) => a.productCnt - b.productCnt,
          scopedSlots: {
            customRender: "productCnt",
          },
          align: "right",
          title: () => {
            return (
              <div style="text-align:center">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>네이버쇼핑에 노출되는 상품수</span>
                  </template>
                  <span>상품수</span>
                </a-tooltip>
              </div>
            );
          },
        },
        {
          sorter: true,
          key: "avgPurchase",
          width: "100px",
          seq: 4,
          etitle: "상위1페이지 제품의 월평균 판매량",
          dataIndex: "avgPurchase",
          sorter: (a, b) => a.avgPurchase - b.avgPurchase,
          scopedSlots: {
            customRender: "avgPurchase",
          },
          align: "right",
          title: () => {
            return (
              <div style="text-align:center">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>네이버쇼핑 상위1페이지 제품의 월평균 판매량</span>
                  </template>
                  <span>평균판매량</span>
                </a-tooltip>
              </div>
            );
          },
        },
        {
          sorter: true,
          key: "avgPrice",
          width: "100px",
          etitle: "상위1페이지 제품의 평균가격",
          dataIndex: "avgPrice",
          sorter: (a, b) => a.avgPrice - b.avgPrice,
          scopedSlots: {
            customRender: "avgPrice",
          },
          seq: 5,
          align: "right",
          title: () => {
            return (
              <div style="text-align:center">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    <span>네이버쇼핑 상위1페이지 제품의 평균가격</span>
                  </template>
                  <span>평균가격</span>
                </a-tooltip>
              </div>
            );
          },
        },
      ],
    };
  },
  watch: {
    keyword() {
      this.queryDataByKeyword();
    },
    sidebar() {
      this.initData();
    },
    code() {
      this.queryDataByCateogry();
    },
  },
  mounted() {
    this.cumdate = getToday();
  },
  methods: {
    onClickEvent(e) {
      //TO-DO
    },
    onCloseEvent() {
      this.showFlag = false;
    },
    async onSaveKeyword() {
      if (this.getUserKey) {
        //
        if (this.tableData.length == 0) {
          showError({
            notify: this.$vs.notify,
            msg: "키워드 검색을 한후에 이용 부탁드립니다.",
          });
        } else if (
          this.selectedRows.length == 0 &&
          this.selectedRows2.length == 0 &&
          this.selectedRows3.length == 0 &&
          this.selectedRows4.length == 0
        ) {
          //to-do
          showError({
            notify: this.$vs.notify,
            msg: "키워드를 선택해주세요",
          });
        } else {
          await this.onSaveKeywordData(this.selectedRows);
          await this.onSaveKeywordData(this.selectedRows2);
          await this.onSaveKeywordData(this.selectedRows3);
          await this.onSaveKeywordData(this.selectedRows4);

          this.closeText = "확인";
          this.msg = "정상적으로 저장이 되었습니다.";
          this.showFlag = true;
        }
      } else {
        this.closeText = "";
        this.msg =
          "<p>회원 전용 서비스입니다.<br/>로그인 후 이용 해 주세요 😘</p>";
        this.showFlag = true;
      }
    },
    onSaveKeywordData(data) {
      var db = firebase.firestore();
      var self = this;
      if (data) {
        data.forEach((item) => {
          var _key = this.getUid + encodeURIComponent(item.relKeyword);

          db.collection("storelink_naver_keyword_user")
            .doc(_key)
            .set(
              {
                uid: self.getUid,
                keyword: item.relKeyword,
                eventtime: new Date(),
                groupname: "",
                dirty: "T",
              },
              {
                merge: true,
              }
            )
            .then(function () {});
        });
      }
    },
    initData() {
      this.tableData = [];
      this.tableData2 = [];
      this.tableData3 = [];
      this.tableData4 = [];
      this.targetData = [];

      if (this.sidebar == SIDEMENU_TYPE.MYKEYWORD) {
        this.onLoadMyKeyword();
      } else if (this.sidebar == SIDEMENU_TYPE.CATEGORY) {
        this.currentTab = SEARCH_TYPE.TOP10TAB;
      } else if (this.sidebar == SIDEMENU_TYPE.COMPETITIVE);
      {
        this.currentTab = SEARCH_TYPE.COMPETITIVETAB;
      }
    },
    async onLoadMyKeyword() {
      var db = firebase.firestore();
      var self = this;
      this.tableData = [];
      this.$vs.loading();

      var _keyword = [];
      await db
        .collection("storelink_naver_keyword_user")
        .where("uid", "==", this.getUid)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            _keyword.push(doc.data().keyword);
          });
        });
      if (_keyword.length > 0) {
        this.tableData = await this.getSnapshots(_keyword);
        this.targetData = this.tableData;
      }
      this.$vs.loading.close();
    },
    queryDataByCateogry() {
      if (this.sidebar == SIDEMENU_TYPE.CATEGORY) {
        this.tableData2 = [];
        this.tableData3 = [];
        this.tableData4 = [];
        this.targetData = [];
        this.getTop10Kwd(this.code);
      }
    },
    queryDataByKeyword() {
      if (this.sidebar == SIDEMENU_TYPE.KEYWORD) {
        this.tableData2 = [];
        this.tableData3 = [];
        this.tableData4 = [];
        this.targetData = [];
        this.getRKwd();
      }
      if (this.sidebar == SIDEMENU_TYPE.COMPETITIVE) {
        this.tableData2 = [];
        this.tableData3 = [];
        this.tableData4 = [];
        this.targetData = [];
        this.get1PageMetaTagTabKwd();
      }
    },
    onSelectTab(val) {
      this.currentTab = val;
      if (this.currentTab == SEARCH_TYPE.AUTOCOMPLEATETAB && this.keyword) {
        this.targetData = [];
        this.getAutoComplteKwd();
      }
      if (this.currentTab == SEARCH_TYPE.VIEWTAB && this.keyword) {
        this.targetData = [];
        this.getViewTabKwd();
      }
      if (this.currentTab == SEARCH_TYPE.SHOPPINGTAB && this.keyword) {
        this.targetData = [];
        this.getShoppingTabKwd();
      }

      if (
        this.currentTab == SEARCH_TYPE.TODAYFAVTAB &&
        this.code &&
        this.tableData2.length == 0
      ) {
        this.targetData = [];
        this.getKeywordRankByCode(this.code, "date", SEARCH_TYPE.TODAYFAVTAB);
      }

      if (
        this.currentTab == SEARCH_TYPE.WEEKFAVTAB &&
        this.code &&
        this.tableData3.length == 0
      ) {
        this.targetData = [];
        this.getKeywordRankByCode(this.code, "week", SEARCH_TYPE.WEEKFAVTAB);
      }

      if (
        this.currentTab == SEARCH_TYPE.MONTHFAVTAB &&
        this.code &&
        this.tableData4.length == 0
      ) {
        this.targetData = [];
        this.getKeywordRankByCode(this.code, "month", SEARCH_TYPE.MONTHFAVTAB);
      }
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys;
      this.selectedRows = selectedRows;
    },
    onSelectChange2(selectedRowKeys, selectedRows) {
      this.selectedRowKeys2 = selectedRowKeys;
      this.selectedRows2 = selectedRows;
    },
    onSelectChange3(selectedRowKeys, selectedRows) {
      this.selectedRowKeys3 = selectedRowKeys;
      this.selectedRows3 = selectedRows;
    },
    onSelectChange4(selectedRowKeys, selectedRows) {
      this.selectedRowKeys4 = selectedRowKeys;
      this.selectedRows4 = selectedRows;
    },
    async getRKwd() {
      var keyword = this.keyword;
      if (this.keyword) {
        this.$vs.loading();
        var self = this;
        this.tableData = [];
        await this.$http
          .post("https://asia-east2-storelink-fnc.cloudfunctions.net/getRKwd", {
            keyword,
          })
          .then(async function (r) {
            await self.onCompensateData(r.data.keywordList);
            setTimeout(function () {
              self.$vs.loading.close();
            }, 1000);
          });
      }
    },
    async getTop10Kwd(code) {
      "onGetNaverKeywordRankByCode";
      var self = this;
      this.tableData = [];
      if (code) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc4-41621.cloudfunctions.net/onGetNaverTop10ByCode",
            {
              cid: code,
            }
          )
          .then(function (r) {
            r.data.ranks.forEach(async (item) => {
              if (item) {
                await self.getCategoryKwd(SEARCH_TYPE.TOP10TAB, item.keyword);
              }
            });
            setTimeout(function () {
              self.$vs.loading.close();
            }, 1000);
          });
      }
    },
    async getKeywordRankByCode(_cid, _date, _searchType) {
      var self = this;

      if (_date == "date") {
        this.tableData2 = [];
      }

      if (_date == "week") {
        this.tableData3 = [];
      }

      if (_date == "month") {
        this.tableData4 = [];
      }

      if (_cid && _date) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc4-41621.cloudfunctions.net/onGetNaverKeywordRankByCode",
            {
              cid: _cid,
              date: _date,
            }
          )
          .then(function (r) {
            r.data.forEach(async (item) => {
              if (item) {
                await self.getCategoryKwd(_searchType, item.keyword);
              }
            });
            self.$vs.loading.close();
          });
      }
    },
    async getKeywordListByCategory() {
      await this.$http
        .post(
          "https://asia-east2-storelink-fnc.cloudfunctions.net/getAutoCompleteByJson",
          {
            keyword,
          }
        )
        .then(function (r) {
          if (r.data.items[0]) {
            r.data.items[0].forEach((item) => {
              if (item) {
                self.getRKwdSigle(
                  SEARCH_TYPE.AUTOCOMPLEATETAB,
                  item.toString()
                );
              }
            });
          }
        });
    },
    async getRKwdSigle(_searchType, keyword) {
      var self = this;

      var _r = await this.getSnapshot(keyword);
      if (_r == null) {
        await this.$http
          .post("https://asia-east2-storelink-fnc.cloudfunctions.net/getRKwd", {
            keyword,
          })
          .then(function (r) {
            if (r.data.keywordList) {
              r.data.keywordList.forEach((item) => {
                if (item.relKeyword == keyword) {
                  if (
                    _searchType == SEARCH_TYPE.AUTOCOMPLEATETAB ||
                    _searchType == SEARCH_TYPE.VIEWTAB ||
                    _searchType == SEARCH_TYPE.SHOPPINGTAB ||
                    _searchType == SEARCH_TYPE.COMPETITIVETAB
                  ) {
                    self.onCompensateDataSingle(
                      _searchType,
                      item,
                      SEARCH_TYPE.DIRTY
                    );
                  }
                }
              });
            }
          });
      } else {
        self.onCompensateDataSingle(_searchType, _r, SEARCH_TYPE.PASS);
      }
    },
    async getCategoryKwd(_searchType, keyword) {
      var self = this;

      await this.$http
        .post("https://asia-east2-storelink-fnc.cloudfunctions.net/getRKwd", {
          keyword,
        })
        .then(function (r) {
          if (r.data.keywordList) {
            r.data.keywordList.forEach((item) => {
              if (item.relKeyword == keyword) {
                self.onCompensateDataSingle(
                  _searchType,
                  item,
                  SEARCH_TYPE.DIRTY
                );
              }
            });
          }
        });
    },
    async getAutoComplteKwd() {
      var keyword = this.keyword;
      var self = this;

      if (this.tableData2.length == 0) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getAutoCompleteByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            if (r.data.items[0]) {
              r.data.items[0].forEach((item) => {
                if (item) {
                  self.getRKwdSigle(SEARCH_TYPE.AUTOCOMPLEATE, item.toString());
                }
              });
            }
          });
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    async getViewTabKwd() {
      var keyword = this.keyword;
      var self = this;

      if (this.tableData3.length == 0) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getViewRecommandByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            if (r.data) {
              r.data.forEach((item) => {
                if (item) {
                  self.getRKwdSigle(SEARCH_TYPE.VIEWTAB, item);
                }
              });
            }
          });
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    async getShoppingTabKwd() {
      var keyword = this.keyword;
      var self = this;
      if (this.tableData4.length == 0) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getUrlByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            if (r.data.relatedTags) {
              r.data.relatedTags.forEach((item) => {
                if (item) {
                  self.getRKwdSigle(SEARCH_TYPE.SHOPPINGTAB, item);
                }
              });
            }
          });
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    async get1PageMetaTagTabKwd() {
      var keyword = this.keyword;
      var self = this;
      var _keymap = new Map();
      if (this.tableData.length == 0) {
        this.$vs.loading();
        await this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getUrlByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            var _r = r.data;
            if (_r.shoppingResult) {
              if (_r.shoppingResult.products) {
                _r.shoppingResult.products.forEach((item) => {
                  if (item.manuTag) {
                    var _loop = item.manuTag.split(",");

                    for (var i = 0; i < _loop.length; i++) {
                      _keymap.set(_loop[i], _loop[i]);
                    }
                  }
                });
              }
            }
          });

        _keymap.forEach((item) => {
          if (item) {
            self.getRKwdSigle(SEARCH_TYPE.COMPETITIVETAB, item);
          }
        });
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    onCompensateData(data) {
      var self = this;
      if (data) {
        var _dummy = [];
        data.forEach((item) => {
          var _t = item;

          // if (item.compIdx == "높음") {
          _t["total"] = item.monthlyMobileQcCnt + item.monthlyPcQcCnt;

          _dummy.push(_t);
          // }
        });
        //잠깐 막음
        this.tableData = _dummy;
        // this.tableData = _dummy.sort(function(a, b) {
        //   // desc
        //   return a.total > b.total ? -1 : a.total < b.total ? 1 : 0;
        // });

        this.tableData = this.tableData.slice(0, 100);
        if (this.tableData) {
          this.tableData.forEach((item) => {
            self.getProductCountByKeyword(
              SEARCH_TYPE.RELATETAB,
              item.relKeyword,
              item.total
            );
          });
        }
        this.targetData = this.tableData;
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    onCompensateDataSingle(_searchType, data, _mode) {
      var self = this;
      if (data) {
        var _t = data;

        if (data.compIdx == "높음") {
          _t["total"] = data.monthlyMobileQcCnt + data.monthlyPcQcCnt;
        }
        if (
          _searchType == SEARCH_TYPE.AUTOCOMPLEATETAB ||
          _searchType == SEARCH_TYPE.TODAYFAVTAB
        ) {
          self.tableData2.push(_t);
          self.targetData = self.tableData2;
        }
        if (
          _searchType == SEARCH_TYPE.VIEWTAB ||
          _searchType == SEARCH_TYPE.WEEKFAVTAB
        ) {
          self.tableData3.push(_t);
          self.targetData = self.tableData3;
        }
        if (
          _searchType == SEARCH_TYPE.SHOPPINGTAB ||
          _searchType == SEARCH_TYPE.MONTHFAVTAB
        ) {
          self.tableData4.push(_t);
          self.targetData = self.tableData4;
        }
        if (
          _searchType == SEARCH_TYPE.TOP10TAB ||
          _searchType == SEARCH_TYPE.COMPETITIVETAB
        ) {
          self.tableData.push(_t);
          self.targetData = self.tableData;
        }

        if (_mode == SEARCH_TYPE.DIRTY) {
          self.getProductCountByKeyword(
            _searchType,
            _t["relKeyword"],
            _t["total"]
          );
        }
        setTimeout(function () {
          self.$vs.loading.close();
        }, 1000);
      }
    },
    async getProductCountByKeyword(_searchType, keyword, total) {
      var self = this;

      var _r = await this.getSnapshot(keyword);

      if (_r == null) {
        this.$http
          .post(
            "https://asia-east2-storelink-fnc.cloudfunctions.net/getUrlByJson",
            {
              keyword,
            }
          )
          .then(function (r) {
            var _totalPurchaseCnt = 0;
            var _totalPrice = 0;
            var _idx = 1;
            if (r.data.shoppingResult) {
              var _productTotal = r.data.shoppingResult.total;

              if (r.data.shoppingResult.products) {
                r.data.shoppingResult.products.forEach((item) => {
                  if (item.adId) {
                    //skip
                  } else if (item.purchaseCnt > 0) {
                    _idx++;
                    _totalPurchaseCnt += item.purchaseCnt;
                    _totalPrice += parseInt(item.price);
                  }
                });

                if (
                  _searchType == SEARCH_TYPE.RELATETAB ||
                  _searchType == SEARCH_TYPE.TOP10TAB ||
                  _searchType == SEARCH_TYPE.COMPETITIVETAB
                ) {
                  self.tableData = self.tableData.filter((item) => {
                    if (item.relKeyword == keyword) {
                      item["productCnt"] = _productTotal;
                      item["avgPurchase"] = parseInt(_totalPurchaseCnt / _idx);
                      item["avgPrice"] = parseInt(_totalPrice / _idx);
                      item["shopPower"] = parseInt(_productTotal / total);
                      item["shopPower"] = item["shopPower"]
                        ? item["shopPower"]
                        : 0;

                      self.onSaveNaverKeywordSnapshot(keyword, item);
                    }
                    return item;
                  });
                  self.targetData = self.tableData;
                }

                if (
                  _searchType == SEARCH_TYPE.AUTOCOMPLEATETAB ||
                  _searchType == SEARCH_TYPE.TODAYFAVTAB
                ) {
                  self.tableData2 = self.tableData2.filter((item) => {
                    if (item.relKeyword == keyword) {
                      item["productCnt"] = _productTotal;
                      item["avgPurchase"] = parseInt(_totalPurchaseCnt / _idx);
                      item["avgPrice"] = parseInt(_totalPrice / _idx);
                      item["shopPower"] = parseInt(_productTotal / total);
                      item["shopPower"] = item["shopPower"]
                        ? item["shopPower"]
                        : 0;
                      self.onSaveNaverKeywordSnapshot(keyword, item);
                    }
                    return item;
                  });
                  self.targetData = self.tableData2;
                }

                if (
                  _searchType == SEARCH_TYPE.VIEWTAB ||
                  _searchType == SEARCH_TYPE.WEEKFAVTAB
                ) {
                  self.tableData3 = self.tableData3.filter((item) => {
                    if (item.relKeyword == keyword) {
                      item["productCnt"] = _productTotal;
                      item["avgPurchase"] = parseInt(_totalPurchaseCnt / _idx);
                      item["avgPrice"] = parseInt(_totalPrice / _idx);
                      item["shopPower"] = parseInt(_productTotal / total);
                      item["shopPower"] = item["shopPower"]
                        ? item["shopPower"]
                        : 0;
                      self.onSaveNaverKeywordSnapshot(keyword, item);
                    }
                    return item;
                  });
                  self.targetData = self.tableData3;
                }

                if (
                  _searchType == SEARCH_TYPE.SHOPPINGTAB ||
                  _searchType == SEARCH_TYPE.MONTHFAVTAB
                ) {
                  self.tableData4 = self.tableData4.filter((item) => {
                    if (item.relKeyword == keyword) {
                      item["productCnt"] = _productTotal;
                      item["avgPurchase"] = parseInt(_totalPurchaseCnt / _idx);
                      item["avgPrice"] = parseInt(_totalPrice / _idx);
                      item["shopPower"] = parseInt(_productTotal / total);
                      item["shopPower"] = item["shopPower"]
                        ? item["shopPower"]
                        : 0;
                      self.onSaveNaverKeywordSnapshot(keyword, item);
                    }
                    return item;
                  });
                  self.targetData = self.tableData4;
                }
              }
            }
          });
      } else {
        if (
          _searchType == SEARCH_TYPE.RELATETAB ||
          _searchType == SEARCH_TYPE.TOP10TAB
        ) {
          this.tableData = this.tableData.filter((item) => {
            if (item.relKeyword == _r.relKeyword) {
              item["productCnt"] = _r.productCnt;
              item["avgPurchase"] = _r.avgPurchase;
              item["avgPrice"] = _r.avgPurchase;
              item["shopPower"] = _r.shopPower;
            }
            return item;
          });
          this.targetData = this.tableData;
        }

        if (
          _searchType == SEARCH_TYPE.AUTOCOMPLEATETAB ||
          _searchType == SEARCH_TYPE.TODAYFAVTAB
        ) {
          this.tableData2 = this.tableData2.filter((item) => {
            if (item.relKeyword == _r.relKeyword) {
              item["productCnt"] = _r.productCnt;
              item["avgPurchase"] = _r.avgPurchase;
              item["avgPrice"] = _r.avgPurchase;
              item["shopPower"] = _r.shopPower;
            }
            return item;
          });
          this.targetData = this.tableData2;
        }

        if (
          _searchType == SEARCH_TYPE.VIEWTAB ||
          _searchType == SEARCH_TYPE.WEEKFAVTAB
        ) {
          this.tableData3 = this.tableData3.filter((item) => {
            if (item.relKeyword == _r.relKeyword) {
              item["productCnt"] = _r.productCnt;
              item["avgPurchase"] = _r.avgPurchase;
              item["avgPrice"] = _r.avgPurchase;
              item["shopPower"] = _r.shopPower;
            }
            return item;
          });
          this.targetData = this.tableData3;
        }

        if (
          _searchType == SEARCH_TYPE.SHOPPINGTAB ||
          _searchType == SEARCH_TYPE.MONTHFAVTAB
        ) {
          this.tableData4 = this.tableData4.filter((item) => {
            if (item.relKeyword == _r.relKeyword) {
              item["productCnt"] = _r.productCnt;
              item["avgPurchase"] = _r.avgPurchase;
              item["avgPrice"] = _r.avgPurchase;
              item["shopPower"] = _r.shopPower;
            }
            return item;
          });
          this.targetData = this.tableData4;
        }
      }
    },
    onSaveNaverKeywordSnapshot(_keywordname, _obj) {
      var _key = encodeURIComponent(_keywordname);
      var db = firebase.firestore();
      var self = this;

      var sfDocRef = db.collection("storelink_naver_keyword").doc(_key);

      return db
        .runTransaction((transaction) => {
          // This code may get re-run multiple times if there are conflicts.
          return transaction.get(sfDocRef).then((sfDoc) => {
            if (!sfDoc.exists) {
              db.collection("storelink_naver_keyword")
                .doc(_key)
                .set(
                  {
                    keyword: _keywordname,
                    obj: _obj,
                    eventtime: new Date(),
                    population: 1,
                    mark: "T",
                  },
                  {
                    merge: true,
                  }
                )
                .then(function () {});
            } else {
              var newPopulation = sfDoc.data().population + 1;
              transaction.update(sfDocRef, {
                population: newPopulation,
                eventtime: new Date(),
                obj: _obj,
              });
            }

            // Add one person to the city population.
            // Note: this could be done without a transaction
            //       by updating the population using FieldValue.increment()
          });
        })
        .then(() => {
          console.log("Transaction successfully committed!");
        })
        .catch((error) => {
          console.log("Transaction failed: ", error);
        });
    },
    async getSnapshot(keyword) {
      var db = firebase.firestore();
      var _r = null;
      await db
        .collection("storelink_naver_keyword")
        .where("keyword", "==", keyword)
        .limit(1)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.data().obj) {
              _r = doc.data().obj;
            }
          });
        });
      return _r;
    },
    async getSnapshots(keywords) {
      var db = firebase.firestore();
      var _r = [];
      await db
        .collection("storelink_naver_keyword")
        .where("keyword", "in", keywords)
        .get()
        .then(function (querySnapshot) {
          querySnapshot.forEach(function (doc) {
            if (doc.data().obj) {
              var _t = doc.data().obj;
              _t["key"] = doc.id;
              _r.push(_t);
            }
          });
        });
      return _r;
    },
  },
};
</script>

<style>
.s-f-tbl th {
  background: white !important;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-radius: 0;
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;

  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

/* .s-f-tbl td:nth-child(2) {
  border-right: 1px solid #e0e0e0;
} */

.s-f-tag1 {
  background: #eb5757;
}

.s-f-tag2 {
  background: #f2994a;
}

.s-f-tag3 {
  background: #828282;
}

.s-f-tag4 {
  background: #56ccf2;
}

.s-f-tag5 {
  background: #0264fb;
}

.s-f-tag {
  width: 62px;
  height: 24px;
  border-radius: 5px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 17px;
  letter-spacing: -0.03em;
  color: #ffffff;
  text-align: center;
  padding-top: 2px;
  margin: 0 auto;
}

.s-f-tbl.deactive .ant-table-placeholder {
  display: none;
}

.s-f-tab-btn1 {
  width: 150px;
  height: 48px;
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  letter-spacing: -0.03em;

  color: #828282;
  padding-top: 15px;
  padding-bottom: 15px;
  border-left: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
  border-top: 1px solid #e0e0e0;
  cursor: pointer;
}

.s-f-tab-btn1.active {
  color: #ffffff;
  background: #000000;
}

.s-f-tab-font1 {
  font-family: "Noto Sans KR", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */

  letter-spacing: -0.03em;

  color: #000000;
}

.s-f-tab-font2 {
  font-family: "Muli";
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.03em;
  padding-top: 6px;

  color: #828282;
}
</style>
